import { useState } from "react";
import { FaRegCopy } from "react-icons/fa";

function TextArea({ placeholderText, handleChange, name, text, className }) {
  const [message, setMessage] = useState("Copiar texto");
  return (
    <div>
      <textarea
        className={className}
        name={name}
        cols="30"
        rows="10"
        placeholder={placeholderText}
        onInput={(e) => handleChange(e)}
        value={text}
      ></textarea>
      {name === "output" ? (
        <div class="tooltip">
          <span
            class="copy-button"
            id="copyButton"
            onClick={(e) => {
              if (text === "") {
                setMessage("No hay texto");
              } else {
                let copyText = document.querySelector(".textarea-output");
                copyText.select();
                copyText.setSelectionRange(0, 99999);
                navigator.clipboard.writeText(copyText.value);
                setMessage("Texto copiado");
              }
            }}
            onMouseLeave={() => setMessage("Copiar texto")}
          >
            <span class="tooltiptext" id="myTooltip">
              {message}
            </span>
            <FaRegCopy />
          </span>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default TextArea;
