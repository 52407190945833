import { useState } from "react";
import "./App.css";
import TextArea from "./components/TextArea";
import { HiOutlineSwitchHorizontal } from "react-icons/hi";

function App() {
  const [text, setText] = useState("");
  const [state, setState] = useState(false);

  const claveMurcielago = "murcielago";

  const handleChange = (e) => {
    let text = document
      .querySelector("[name ='mur']")
      .value.toLowerCase()
      .split("");
    let state = document.querySelector("[name ='selectMode']").checked;
    setState(state);

    if (!state) {
      setText(
        text
          .map((letter) => {
            return claveMurcielago.split("").indexOf(letter) !== -1
              ? claveMurcielago.split("").indexOf(letter)
              : letter;
          })
          .join("")
      );
    } else {
      setText(
        text
          .map((letter) => {
            let letter2 = parseInt(letter);

            return !isNaN(letter2)
              ? claveMurcielago.split("")[letter2]
              : letter;
          })
          .join("")
      );
    }
  };

  return (
    <>
      <section className="section">
        <div className="container">
          <h1>Traductor de clave Murciélago</h1>

          <form className="form" onInput={(e) => handleChange(e)}>
            <div className="lang-selector">
              <p>{state ? "Clave murciélago" : "Español"}</p>
              <label className="switch" htmlFor="langSelector">
                <HiOutlineSwitchHorizontal />
              </label>
              <input
                id="langSelector"
                htmlFor="langSelector"
                type="checkbox"
                name="selectMode"
              />
              <p>{!state ? "Clave murciélago" : "Español"}</p>
            </div>

            <div className="textareas-container">
              <TextArea
                name="mur"
                placeholderText={
                  !state
                    ? "Tu texto a codificar"
                    : "Tu texto en clave murciélago"
                }
                handleChange={handleChange}
                className="textarea-input"
              />

              <TextArea
                name="output"
                placeholderText={
                  !state ? "Texto codificado" : "Texto decodificado"
                }
                handleChange={handleChange}
                text={text}
                readOnly
                className="textarea-output"
              />
            </div>
          </form>
        </div>
      </section>
      <footer class="footer">
        <p>Taductor de clave Murciélago | ©2024</p>
        <p>Todos los derechos reservados.</p>
        <p></p>
        <br />
        <p>
          Desarrollado por:{" "}
          <a href="https://github.com/EstebanCarrilloG">Esteban Carrillo</a>
        </p>
      </footer>
    </>
  );
}

export default App;
